












































import { cloneDeep } from 'lodash';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { Global } from '@/store';

@Component({ components: {} })

export default class UsersList extends Vue {
    @Global.State('lang') lang;

    @Prop() users_list;

    sort_by = 'email';
    descending = false;
    loading = true;
    table_data = null;
    pagination = {
      rowsPerPage: 15
    };

    mounted(){
      // const email_header = this.headers.find((header) => header.value === 'email');
      // console.log(email_header)
      // this.changeSort(email_header);
      // this.pagination.totalItems = this.users_list.length;
      this.table_data = cloneDeep(this.users_list);
      this.loading = false;
    }

    get headers() {
      return [
        { text: this.$t('Email'), value: 'email', class: this.lang === 'en' ? 'text-xs-left subheading' : 'text-xs-right subheading', sortable: true, sort_field: 'email' },
        { text: this.$t('First Name'), value: 'first_name', class: this.lang === 'en' ? 'text-xs-left subheading' : 'text-xs-right subheading', sortable: true, sort_field: 'first_name'},
        { text: this.$t('Last Name'), value: 'last_name', class: this.lang === 'en' ? 'text-xs-left subheading' : 'text-xs-right subheading', sortable: true, sort_field: 'last_name'},
        { text: this.$t('Phone Number'), value: 'phone_number', class: this.lang === 'en' ? 'text-xs-left subheading' : 'text-xs-right subheading', sortable: true, sort_field: 'phone_number' },
        { text: this.$t('Actions'), value: 'actions', class: this.lang === 'en' ? 'text-xs-left subheading' : 'text-xs-right subheading', sortable: false }
      ];
    }

    @Watch('users_list', {deep: true, immediate: true})
    updateTableData() {
      this.loading = true;
      this.table_data = cloneDeep(this.users_list);
      this.loading = false;
    }

  //   changeSort(header_data) {
  //     if (!header_data.hasOwnProperty('sortable') || header_data.hasOwnProperty('sortable') && !header_data.sortable){
  //       return;
  //     }
  //     this.loading = true;

  //     if (this.sort_by === header_data.sort_field) {
  //       this.descending = !this.descending;
  //     }else {
  //       this.sort_by = header_data.sort_field;
  //       this.descending = false;
  //     }

  //     const data = cloneDeep(this.users_list);
  //     console.log(data)
  //     console.log(this.descending)
  //     console.log(this.sort_by)

  //     if (this.descending) {
  //       data.sort((row1, row2) => {
  //         (row2[this.sort_by] || '').toString().localeCompare((row1[this.sort_by] || '').toString(), undefined, {numeric: true, sensitivity: 'base'});
  //       });
  //     }else {
  //       data.sort((row1, row2) => {
  //         (row1[this.sort_by] || '').toString().localeCompare((row2[this.sort_by] || '').toString(), undefined, {numeric: true, sensitivity: 'base'})
  //       });
  //     }
  //     console.log(data)
  //     this.$set(this, 'table_data', data);
  //     console.log(this.table_data)
  //     this.loading = false;
  // }
}

